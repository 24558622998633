import { ILocationFragment } from "@/graphql/fragments/__generated__/location.types";
import { IObituaryFragment } from "@/graphql/fragments/__generated__/obituary.types";
import { Stack } from "@mui/material";
import { CTAListenStory } from "./CTAListenStory";
import { CTAPlantTree } from "./CTAPlantTree";
import { CTASendFlowers } from "./CTASendFlowers";
import { CTAShareObituary } from "./CTAShareObituary";
import { CTAViewMemoryBoard } from "./CTAViewMemoryBoard";

interface CTABoxMobileProps {
  obituary: IObituaryFragment;
  funeralHome?: ILocationFragment;
  treePlantingUrl?: string;
  hasMemories?: boolean;
  isArchiveObit?: boolean;
}

export const CTABoxMobile = ({
  obituary,
  treePlantingUrl,
  hasMemories,
}: CTABoxMobileProps) => {
  return (
    <Stack
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      bgcolor="background.paper"
      direction="row"
      justifyContent="space-evenly"
      p={1}
      px={0}
      borderTop="1px solid"
      borderColor="ButtonFace"
      boxShadow="0px -2px 5px rgba(0, 0, 0, 0.03)"
    >
      <CTAViewMemoryBoard
        title="Memory"
        obituary={obituary}
        hasMemories={hasMemories}
      />
      <CTAListenStory title="Listen" obituary={obituary} />
      <CTAShareObituary title="Share" obituary={obituary} />
      <CTAPlantTree title="Plant a tree" treePlantingUrl={treePlantingUrl} />
      <CTASendFlowers title="Send flowers" obituary={obituary} />
    </Stack>
  );
};
